
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { getDeal } from '@/services/api';

import { PartialDeal } from '@/interfaces/Deal';

const DEFAULT_FORM_VALUES: PartialDeal & {} = {
  name: '',
  description: '',
  image: '',
  link: '',
  discount: 0
};

export default defineComponent({
  setup() {
    const dealId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const data = ref(DEFAULT_FORM_VALUES);

    onMounted(async() => {
      const res = await getDeal({ dealId });
      data.value = res.data;
    });

    return {
      page,
      data
    };
  }
});
